<template>
  <div id="particulars" style="padding-top: 126px">
    <app-header />
    <!-- 位置 -->
    <div class="current_position w">
      <div class="current_position_text">当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="(item, i) in $route.meta.breadcrumb"
          :to="item.path ? { path: item.path } : ''"
          :key="i"
          >{{ item.name }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <!-- 详情 -->
    <div class="particularsList w">
      <div class="left">
        <img :src="particularsList.agency_img" alt="" />
      </div>
      <div class="right">
        <div class="th">{{ particularsList.agency_name }}</div>
        <div class="tb">单位性质：{{ particularsList.unit_nature }}</div>
        <div class="tb">所属行业：{{ particularsList.industry }}</div>
        <div class="tb">
          联系人：{{ particularsList.contacts }}
          <span>联系电话：{{ particularsList.mobile }}</span>
        </div>
        <div class="tb">地址:{{ particularsList.address }}</div>
      </div>
    </div>
    <!-- 主营业务 -->
    <div class="business">
      <div class="business_th w">
        <div class="box"></div>
        <div>主营业务</div>
      </div>
      <div class="business_tb w">
        {{ particularsList.business }}
      </div>
    </div>

    <app-footer />
  </div>
</template>

<script>
// import './particulars.css';
import Header from '../../components/Header/header.vue';
import Footer from '../../components/Footer/footer.vue';
import { service_agency_info } from '../../api/policies.js';
export default {
  data() {
    return {
      particularsList: {}, //机构详情
    };
  },
  created() {
    this.service_agency_info();
    console.log();
  },
  methods: {
    async service_agency_info() {
      let id = this.$route.params.id;
      const parameter = {
        id,
      };

      const { result } = await service_agency_info(parameter);

      this.particularsList = result;
      console.log('this.particularsList', this.particularsList);
    },
  },
  components: { 'app-header': Header, 'app-footer': Footer },
};
</script>

<style scoped>
.particularsList .right {
  width: 750px;
}
.w {
  width: 1310px;
  margin: 0 auto;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* 	overflow: hidden;
   */
}

.current_position {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.current_position_text {
  font-size: 14px;
  color: #838383;
}
.d8f {
  color: #0d8eff;
}
.particularsList {
  display: flex;
  height: 300px;
  margin-top: 25px;
  padding: 66px 0 66px 50px;
  background: #cce7ff;
}
.particularsList .left {
  object-fit: cover;
  width: 400px;
  height: 167px;
  background-color: #6cf;
  border: 1px solid #e8e8e8;
  margin-right: 50px;
}
.particularsList .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.particularsList .right .th {
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  color: #333333;
  padding-bottom: 5px;
  word-break: break-all;
}
.particularsList .right .tb {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #595959;
  margin-top: 14px;
  line-height: 18px;
  word-break: break-all;
}
.particularsList .right .tb span {
  padding-left: 90px;
  word-break: break-all;
}
.business {
  margin-top: 30px;
  min-height: 317px;
  margin-bottom: 30px;
  word-break: break-word;
}
.business .business_th {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #333333;
}
.business .business_th .box {
  width: 6px;
  height: 16px;
  background-color: #0d8eff;
  margin-right: 10px;
}
.business .business_tb {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #333333;
}
</style>
